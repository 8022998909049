import moment from "moment";


export class UploadStatus {
  static Start = 0;
  static Validation = 1;
  static Upload = 3;
}

export const getDomainesMetiers = () => {
  return [
    {
      id: 73,
      libelle: "Développement Application",
      libelle_en: "DEV",
      code: "71",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 5,
        name: "Activités de support à l'exploration subsurface",
        libelle_en: "Subsurface exploration support activities",
        code: "C5"
      },
      parent: null
    },
    {
      id: 71,
      libelle: "Développement Applications",
      libelle_en: "Développement Application",
      code: "71",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 1,
        name: "Services d'ingénierie",
        libelle_en: "Engineering services",
        code: "C1"
      },
      parent: null
    },
    {
      id: 70,
      libelle: "fsafas",
      libelle_en: null,
      code: "fsdafasd",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 6,
        name: "Activités de support au transport et approvisionnement offshore et à la gestion des déchets",
        libelle_en: "Support activities for offshore transport and supply and waste management",
        code: "C6"
      },
      parent: null
    },
    {
      id: 69,
      libelle: "DM TEST",
      libelle_en: "Fdsfsa",
      code: "DM",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 68,
      libelle: "Pentest",
      libelle_en: "Pentest",
      code: "DM63",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 1,
        name: "Services d'ingénierie",
        libelle_en: "Engineering services",
        code: "C1"
      },
      parent: null
    },
    {
      id: 67,
      libelle: "MAMTEST",
      libelle_en: null,
      code: "MAM",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 65,
      libelle: "Services de maintenance et de réparation des équipements en mer (Topsides, Système de production sous-marin, mouillage, pipelines, ombilics, risers, …)",
      libelle_en: null,
      code: "DM65",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 13,
        name: "Activités de maintenance et réparation",
        libelle_en: "Maintenance and repair activities",
        code: "C13"
      },
      parent: null
    },
    {
      id: 64,
      libelle: "Maintenance de matériels de sécurité",
      libelle_en: null,
      code: "DM64",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 13,
        name: "Activités de maintenance et réparation",
        libelle_en: "Maintenance and repair activities",
        code: "C13"
      },
      parent: null
    },
    {
      id: 63,
      libelle: "Services de maintenance et de réparation des équipements a terre (Véhicules, signalisation, station de service)",
      libelle_en: null,
      code: "DM63",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 13,
        name: "Activités de maintenance et réparation",
        libelle_en: "Maintenance and repair activities",
        code: "C13"
      },
      parent: null
    },
    {
      id: 62,
      libelle: "Assurance",
      libelle_en: null,
      code: "DM62",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 12,
        name: "Finance et Assurance",
        libelle_en: "Finance and Insurance",
        code: "C12"
      },
      parent: null
    },
    {
      id: 61,
      libelle: "Finance",
      libelle_en: null,
      code: "DM61",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 12,
        name: "Finance et Assurance",
        libelle_en: "Finance and Insurance",
        code: "C12"
      },
      parent: null
    },
    {
      id: 60,
      libelle: "Expertise et conseil de complexité « élevée » (OA/QC, HSE, Analyse de risque. soudage)",
      libelle_en: null,
      code: "DM60",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 11,
        name: "Expertise et conseil",
        libelle_en: "Expertise and advice",
        code: "C11"
      },
      parent: null
    },
    {
      id: 59,
      libelle: "Expertise et conseil de complexité « modeste » (Administration, Contrôle & Gestion de documents)",
      libelle_en: null,
      code: "DM59",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 11,
        name: "Expertise et conseil",
        libelle_en: "Expertise and advice",
        code: "C11"
      },
      parent: null
    },
    {
      id: 58,
      libelle: "Certification",
      libelle_en: null,
      code: "DM58",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 10,
        name: "Services d'inspection, de relève, d'essai et de certification",
        libelle_en: "Inspection, survey, testing and certification services",
        code: "C10"
      },
      parent: null
    },
    {
      id: 57,
      libelle: "Essai",
      libelle_en: null,
      code: "DM57",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 10,
        name: "Services d'inspection, de relève, d'essai et de certification",
        libelle_en: "Inspection, survey, testing and certification services",
        code: "C10"
      },
      parent: null
    },
    {
      id: 56,
      libelle: "Inspection & relève",
      libelle_en: null,
      code: "DM56",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 10,
        name: "Services d'inspection, de relève, d'essai et de certification",
        libelle_en: "Inspection, survey, testing and certification services",
        code: "C10"
      },
      parent: null
    },
    {
      id: 55,
      libelle: "Services aux activités de branchement et de mise en service (commissioning)",
      libelle_en: null,
      code: "DM55",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 9,
        name: "Activités de support aux opérations & installations marines",
        libelle_en: "Support activities for marine operations & installations",
        code: "C9"
      },
      parent: null
    },
    {
      id: 54,
      libelle: "Service aux activités de pré-mise en service (Pre-commissioning)",
      libelle_en: null,
      code: "DM54",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 9,
        name: "Activités de support aux opérations & installations marines",
        libelle_en: "Support activities for marine operations & installations",
        code: "C9"
      },
      parent: null
    },
    {
      id: 53,
      libelle: "Services aux installations marines (Système de production sous-marin, mouillage. pipelines. ombilics. risers... )",
      libelle_en: null,
      code: "DM53",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 9,
        name: "Activités de support aux opérations & installations marines",
        libelle_en: "Support activities for marine operations & installations",
        code: "C9"
      },
      parent: null
    },
    {
      id: 52,
      libelle: "Services aux opérations marines (Dragage, sondages, inspection, plongée)",
      libelle_en: null,
      code: "DM52",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 9,
        name: "Activités de support aux opérations & installations marines",
        libelle_en: "Support activities for marine operations & installations",
        code: "C9"
      },
      parent: null
    },
    {
      id: 51,
      libelle: "Support aux activités des systèmes (Logiciel, programmation, maintenance, conseil …)",
      libelle_en: null,
      code: "DM51",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 50,
      categorie: {
        id: 8,
        name: "Systèmes d'information, informatique, communication",
        libelle_en: "Information systems, IT, communication",
        code: "C8"
      },
      parent: null
    },
    {
      id: 50,
      libelle: "Support à l'installation des systèmes",
      libelle_en: null,
      code: "DM50",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 8,
        name: "Systèmes d'information, informatique, communication",
        libelle_en: "Information systems, IT, communication",
        code: "C8"
      },
      parent: null
    },
    {
      id: 49,
      libelle: "Support aux sites & Installations (Nettoyage, sécurité, protection incendie, ...)",
      libelle_en: null,
      code: "DM49",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 7,
        name: "Activités de support aux personnes, installations et Hygiène, Sécurité et Environnement (HSE)",
        libelle_en: "Support activities for people, facilities and Health, Safety and Environment (HSE)",
        code: "C7"
      },
      parent: null
    },
    {
      id: 48,
      libelle: "Échafaudages et travaux en hauteur",
      libelle_en: null,
      code: "DM48",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: 30,
      percentPersonnelSN: 30,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 7,
        name: "Activités de support aux personnes, installations et Hygiène, Sécurité et Environnement (HSE)",
        libelle_en: "Support activities for people, facilities and Health, Safety and Environment (HSE)",
        code: "C7"
      },
      parent: null
    },
    {
      id: 47,
      libelle: "Conciergerie et Restauration collective",
      libelle_en: null,
      code: "DM47",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 30,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 7,
        name: "Activités de support aux personnes, installations et Hygiène, Sécurité et Environnement (HSE)",
        libelle_en: "Support activities for people, facilities and Health, Safety and Environment (HSE)",
        code: "C7"
      },
      parent: null
    },
    {
      id: 46,
      libelle: "Hébergement (hôtellerie)",
      libelle_en: null,
      code: "DM46",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 90,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 7,
        name: "Activités de support aux personnes, installations et Hygiène, Sécurité et Environnement (HSE)",
        libelle_en: "Support activities for people, facilities and Health, Safety and Environment (HSE)",
        code: "C7"
      },
      parent: null
    },
    {
      id: 45,
      libelle: "Location de bureaux",
      libelle_en: null,
      code: "DM45",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 90,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 7,
        name: "Activités de support aux personnes, installations et Hygiène, Sécurité et Environnement (HSE)",
        libelle_en: "Support activities for people, facilities and Health, Safety and Environment (HSE)",
        code: "C7"
      },
      parent: null
    },
    {
      id: 44,
      libelle: "Support aux personnes (Sécurité, sante,...)",
      libelle_en: null,
      code: "DM44",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 7,
        name: "Activités de support aux personnes, installations et Hygiène, Sécurité et Environnement (HSE)",
        libelle_en: "Support activities for people, facilities and Health, Safety and Environment (HSE)",
        code: "C7"
      },
      parent: null
    },
    {
      id: 43,
      libelle: "Support ponctuel (Location de moyen de levage, ...)",
      libelle_en: null,
      code: "DM43",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 50,
      categorie: {
        id: 6,
        name: "Activités de support au transport et approvisionnement offshore et à la gestion des déchets",
        libelle_en: "Support activities for offshore transport and supply and waste management",
        code: "C6"
      },
      parent: null
    },
    {
      id: 42,
      libelle: "Support régulier onshore (Gestion de déchets et nettoyage citerne, transport routier. matériels Pt véhicules lourds …)",
      libelle_en: null,
      code: "DM42",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 50,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 50,
      categorie: {
        id: 6,
        name: "Activités de support au transport et approvisionnement offshore et à la gestion des déchets",
        libelle_en: "Support activities for offshore transport and supply and waste management",
        code: "C6"
      },
      parent: null
    },
    {
      id: 41,
      libelle: "Support régulier offshore {Remoqueurs, bateaux d'approvisionnement, gestion de dechets )",
      libelle_en: null,
      code: "DM41",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 50,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 50,
      categorie: {
        id: 6,
        name: "Activités de support au transport et approvisionnement offshore et à la gestion des déchets",
        libelle_en: "Support activities for offshore transport and supply and waste management",
        code: "C6"
      },
      parent: null
    },
    {
      id: 40,
      libelle: "Transport du personnel vers les plateformes (via hélicoptères)",
      libelle_en: null,
      code: "DM40",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: 40,
      percentPersonnelSN: 50,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 6,
        name: "Activités de support au transport et approvisionnement offshore et à la gestion des déchets",
        libelle_en: "Support activities for offshore transport and supply and waste management",
        code: "C6"
      },
      parent: null
    },
    {
      id: 39,
      libelle: "Gestion du pare à tubes",
      libelle_en: null,
      code: "DM39",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 50,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 6,
        name: "Activités de support au transport et approvisionnement offshore et à la gestion des déchets",
        libelle_en: "Support activities for offshore transport and supply and waste management",
        code: "C6"
      },
      parent: null
    },
    {
      id: 38,
      libelle: "Base logistique",
      libelle_en: null,
      code: "DM38",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 60,
      percentPersonnelSN: 50,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 6,
        name: "Activités de support au transport et approvisionnement offshore et à la gestion des déchets",
        libelle_en: "Support activities for offshore transport and supply and waste management",
        code: "C6"
      },
      parent: null
    },
    {
      id: 36,
      libelle: "Services d'analyses de données sismiques",
      libelle_en: null,
      code: "DM36",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 5,
        name: "Activités de support à l'exploration subsurface",
        libelle_en: "Subsurface exploration support activities",
        code: "C5"
      },
      parent: null
    },
    {
      id: 35,
      libelle: "Services d'acquisition de données sismiques",
      libelle_en: null,
      code: "DM35",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 5,
        name: "Activités de support à l'exploration subsurface",
        libelle_en: "Subsurface exploration support activities",
        code: "C5"
      },
      parent: null
    },
    {
      id: 34,
      libelle: "Service de support à la complétion (achèvement de puits)",
      libelle_en: null,
      code: "DM34",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 4,
        name: "Activités de support au forage",
        libelle_en: "Drilling support activities",
        code: "C4"
      },
      parent: null
    },
    {
      id: 33,
      libelle: "Machine shop",
      libelle_en: null,
      code: "DM33",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 10,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 50,
      categorie: {
        id: 4,
        name: "Activités de support au forage",
        libelle_en: "Drilling support activities",
        code: "C4"
      },
      parent: null
    },
    {
      id: 32,
      libelle: "Nettoyage des citernes (tank cleaning)",
      libelle_en: null,
      code: "DM32",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 50,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: 50,
      categorie: {
        id: 4,
        name: "Activités de support au forage",
        libelle_en: "Drilling support activities",
        code: "C4"
      },
      parent: null
    },
    {
      id: 31,
      libelle: "Transport et traitement de résidus de forage (waste management)",
      libelle_en: null,
      code: "DM31",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 4,
        name: "Activités de support au forage",
        libelle_en: "Drilling support activities",
        code: "C4"
      },
      parent: null
    },
    {
      id: 30,
      libelle: "Service de support au forage",
      libelle_en: null,
      code: "DM30",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 4,
        name: "Activités de support au forage",
        libelle_en: "Drilling support activities",
        code: "C4"
      },
      parent: null
    },
    {
      id: 29,
      libelle: "Service de support aux études de réservoir",
      libelle_en: null,
      code: "DM29",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 4,
        name: "Activités de support au forage",
        libelle_en: "Drilling support activities",
        code: "C4"
      },
      parent: null
    },
    {
      id: 28,
      libelle: "Travaux BTP",
      libelle_en: null,
      code: "DM28",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: 30,
      percentPersonnelSN: 90,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: 90,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 27,
      libelle: "Structures en béton lourdes (Caisson pour jetée, ...)",
      libelle_en: null,
      code: "DM27",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 26,
      libelle: "Structures en béton légers (matelas de protection de pipelines)",
      libelle_en: null,
      code: "DM26",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 50,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 25,
      libelle: "Structures en acier lourdes ( >250 t: Arche support pour riser/ ombilic, Fleche de torchère)",
      libelle_en: null,
      code: "DM25",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 24,
      libelle: "Structures en acier moyennes (50t- 250 t: fondations type mudmat, pile ou ancres a succion)",
      libelle_en: null,
      code: "DM24",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 23,
      libelle: "Structures en acier légères (<50 tonnes : petites structures soudées types PLET, fondations type mudmat, guidage et supportage de têtes de puits)",
      libelle_en: null,
      code: "DM23",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: 51,
      percentPersonnelSN: 50,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: 51,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 22,
      libelle: "Lires de dilatation et connexion d'équipements sous-marins (Spools & Jumpers)",
      libelle_en: null,
      code: "DM22",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 21,
      libelle: "Ombilicaux",
      libelle_en: null,
      code: "DM21",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "NON_EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 20,
      libelle: "Riser Flexibles",
      libelle_en: null,
      code: "DM20",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "NON_EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 19,
      libelle: "Pipelines et risers rigides (Fabrication Offshore)",
      libelle_en: null,
      code: "DM19",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 18,
      libelle: "Pipelines et risers rigides (Fabrication Onshore)",
      libelle_en: null,
      code: "DM18",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 17,
      libelle: "Systèmes de production sous-marine (manifold)",
      libelle_en: null,
      code: "DM17",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 16,
      libelle: "Systèmes de production sous-marine complexes (Xtree, têtes de puits)",
      libelle_en: null,
      code: "DM16",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "NON_EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 15,
      libelle: "Module de Quartier d'habitation offshore",
      libelle_en: null,
      code: "DM15",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "NON_EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 14,
      libelle: "Piperack pour Topsides",
      libelle_en: null,
      code: "DM14",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "NON_EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 13,
      libelle: "Modules pour Topsides {Process)",
      libelle_en: null,
      code: "DM13",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "NON_EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 3,
        name: "Fabrication et construction",
        libelle_en: "Manufacturing & construction",
        code: "C3"
      },
      parent: null
    },
    {
      id: 12,
      libelle: "Séton et armatures pour la construction de structures",
      libelle_en: null,
      code: "DM12",
      regime: "EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 11,
      libelle: "Éléments en composés époxy renforcés (fibre de verre)",
      libelle_en: null,
      code: "DM11",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 10,
      libelle: "Élingage en acier et accessoires d'amarrage",
      libelle_en: null,
      code: "DM10",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 9,
      libelle: "Peintures spéciales (protection anti-corrosion, ...)",
      libelle_en: null,
      code: "DM9",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 8,
      libelle: "Ciments et bentonite pour le forage",
      libelle_en: null,
      code: "DM8",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "EXCLUSIF",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 7,
      libelle: "Accessoires de tuyauterie (piping, vannes, pompes)",
      libelle_en: null,
      code: "DM7",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 6,
      libelle: "Câbles électriques (HV)",
      libelle_en: null,
      code: "DM6",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 5,
      libelle: "Câbles électriques (LV)",
      libelle_en: null,
      code: "DM5",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 4,
      libelle: "Acier (plaques, tuyaux, profiles, etc.)",
      libelle_en: null,
      code: "DM4",
      regime: "MIXTE",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 2,
        name: "Achat et approvisionnement de matériaux",
        libelle_en: "Purchase and supply of materials",
        code: "C2"
      },
      parent: null
    },
    {
      id: 3,
      libelle: "Études détaillées de réalisation",
      libelle_en: null,
      code: "DM3",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 1,
        name: "Services d'ingénierie",
        libelle_en: "Engineering services",
        code: "C1"
      },
      parent: null
    },
    {
      id: 2,
      libelle: "Études de base (FEED)",
      libelle_en: null,
      code: "DM2",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 1,
        name: "Services d'ingénierie",
        libelle_en: "Engineering services",
        code: "C1"
      },
      parent: null
    },
    {
      id: 1,
      libelle: "Études conceptuelles et Avant-projet",
      libelle_en: null,
      code: "DM1",
      regime: "NON_EXCLUSIF",
      capitalSNMin: null,
      percentCapitalSN: null,
      percentPersonnelSN: null,
      regimeCinqAns: "MIXTE",
      percentCapitalSNCinqAns: null,
      percentPersonnelSNCinqAns: null,
      categorie: {
        id: 1,
        name: "Services d'ingénierie",
        libelle_en: "Engineering services",
        code: "C1"
      },
      parent: null
    }
  ]
}

export const getNaturesPrestations = () => {
  return [
    {
      id: 7,
      libelle: "MARIE",
      code: "MAM"
    },
    {
      id: 5,
      libelle: "MAM",
      code: "MARIE"
    },
    {
      id: 3,
      libelle: "Fournitures",
      code: "FOURNITURES"
    },
    {
      id: 2,
      libelle: "Travaux",
      code: "TRAVAUX"
    },
    {
      id: 1,
      libelle: "Services",
      code: "SERVICES"
    }
  ]
}

export const mapDomainesMetiers = () => {
  const domainesMetiers = getDomainesMetiers();
  return domainesMetiers.reduce((hash, domaineMetier) => ({ ...hash, [domaineMetier.code.toLowerCase()]: domaineMetier }), {})
}

export const mapNaturesPrestations = () => {
  const naturesPrestations = getNaturesPrestations();
  return naturesPrestations.reduce((hash, naturePrestation) => ({ ...hash, [naturePrestation.code.toLowerCase()]: naturePrestation }), {})
}

export function mustBeBeforeDateLancement(value, siblings) {
  const attributionDate = moment(value, "DD/MM/YYYY");
  const launchingDate = moment(siblings.datePrevuLancement, "DD/MM/YYYY");
  return attributionDate > launchingDate;
}

export function mustBeBeforeDateAttribution(value, siblings) {
  const attributionDate = moment(value, "DD/MM/YYYY");
  const launchingDate = moment(siblings.datePrevuLAttribution, "DD/MM/YYYY");
  return attributionDate < launchingDate;
}

export function mustBeAnExistingRegime(value) {
  const regimesMap = { 'EXCLUSIF': 'EXCLUSIF', 'MIXTE': 'MIXTE', 'NON_EXCLUSIF': 'NON_EXCLUSIF' };
  const codes = value.split(",");
  // console.log({ regimesMap });
  for (let index = 0; index < codes.length; index++) {
    const codeKey = codes[index].toUpperCase().trim();
    // console.log({ codeKey })
    if (!regimesMap[codeKey])
      return false;
  }
  return true;
}


export function mustBeExistingDomaineMetierCode(value) {
  const domaineMetierMap = mapDomainesMetiers();
  const codes = value.split(",");
  for (let index = 0; index < codes.length; index++) {
    const codeKey = codes[index].toLowerCase();
    if (!domaineMetierMap[codeKey])
      return false;
  }
  return true;
}

export function mustBeExistingNaturesPrestationsCode(value) {
  const naturesPrestationsMap = mapNaturesPrestations();
  const codes = value.split(",");
  for (let index = 0; index < codes.length; index++) {
    const codeKey = codes[index].toLowerCase();
    if (!naturesPrestationsMap[codeKey])
      return false;
  }
  return true;
}

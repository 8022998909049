<template>
  <ul class="dropdown__list">
    <li
      class="dropdown__list-item"
      v-for="option in array.cols.filter((col) => col.index !== column.index)"
      :key="option.index"
      @click="handleSwapColumns(column.index, option.index)"
    >
      {{ option.label }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "th-dropdown",
  props: ["array", "column"],
  methods: {
    handleSwapColumns(i, j) {
      this.$emit("columnSwapped", i, j);
    },
  },
};
</script>

<style scoped>
.dropdown__list {
  /*border: 1px solid;
  position: absolute;
  z-index: auto;*/
}
.dropdown__list-item {
  font-size: 10px;
  cursor: pointer;
}
</style>
<template>
  <div class="upload__wrapper">
    <div class="upload__actions">
      <div class="upload__desc">
        <a href="/assets/correspondances-domaines-metiers.xlsx"
          ><i class="pi pi-download"></i> Télécharger la correspondance des codes des domaines métiers.</a
        >
      </div>
      <div class="upload__actions__elements">
        <input
          type="file"
          name="projet_marche_file_upload"
          ref="projetMarcheFileUpload"
          id="id_projet_marche_file_upload"
          @change.prevent="onFileChanged"
          v-show="currentStatus === 0"
        />
        <button @click="restart" v-show="currentStatus < 3 || processState === 'FAILED'">Recommencer</button>
        <button @click="performRowsValidation" v-show="currentStatus === 1">Suivant</button>
      </div>
    </div>
    <div class="upload__recap" v-show="currentStatus === 1">
      <table class="upload__table">
        <thead>
          <tr>
            <th v-for="column in array.cols" :key="column.index" class="upload__table__th">
              {{ column.label }}
              <th-dropdown :array="array" :column="column" @columnSwapped="onColumnsSwapped" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, i) in array.rows"
            :key="i"
            :set="(v = v$.values.$each.$response.$errors && v$.values.$each.$response.$errors[i])"
          >
            <td
              v-for="(col, j) in array.previousCol"
              :class="{
                'is-invalid': v && isColumnInvalid(v, col),
              }"
              :key="j"
            >
              {{ row[col.index] }}
            </td>
          </tr>
        </tbody>
      </table>
      <strong
        ><small v-show="invalidMsg" class="is-invalid-text">{{ invalidMsg }}</small></strong
      >
    </div>

    <p class="" v-show="currentStatus === 3 && processState === 'PENDING'">Chargement des données en cours ...</p>

    <p class="" v-show="currentStatus === 3 && processState === 'FAILED'">
      Le chargement des données a été interrompu. Cliquez sur le boutton "Recommencer" ou contactez votre
      administrateur.
    </p>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

import {
  UploadStatus,
  mustBeBeforeDateAttribution,
  mustBeBeforeDateLancement,
  mustBeExistingDomaineMetierCode,
  mustBeExistingNaturesPrestationsCode,
  mustBeAnExistingRegime,
} from './utils';
import ThDropdown from './th-dropdown.vue';
import moment from 'moment';

const SHEET_INDEX = 0;

export default {
  name: 'csv-uploader',
  components: {
    ThDropdown,
  },
  setup: () => ({ v$: useVuelidate() }),

  data: () => ({
    currentStatus: UploadStatus.Start,
    invalidMsg: null,
    headerDefinition: [
      'Réference',
      'Intitulé',
      'Description',
      'Date prévu de lancement',
      "Date prévu d'attribution",
      'Régimes',
      'Nature des prestations',
      'Domaines métiers',
    ],
    mapping: {
      0: 'reference',
      1: 'intitule',
      2: 'description',
      3: 'datePrevuLancement',
      4: 'datePrevuLAttribution',
      5: 'regimes',
      6: 'naturePrestationCode',
      7: 'domaineMetiersCodes',
    },
    values: [],
    array: {
      previousCol: [],
      cols: [],
      rows: [],
    },
  }),

  props: ['reset', 'processState'],

  validations: {
    values: {
      $each: helpers.forEach({
        intitule: {
          required,
        },
        reference: {
          required,
        },
        description: {
          required,
        },
        datePrevuLAttribution: {
          required,
          maxValue: mustBeBeforeDateLancement,
        },
        datePrevuLancement: {
          required,
          minValue: mustBeBeforeDateAttribution,
        },
        regimes: {
          required,
          mustBeAnExistingRegime,
        },
        naturePrestationCode: {
          required,
          mustBeExistingNaturesPrestationsCode,
        },
        domaineMetiersCodes: {
          required,
          mustBeExistingDomaineMetierCode,
        },
      }),
    },
  },
  methods: {
    getNextCharacter(currentCharCode) {
      currentCharCode += 1;
      let currentLetter = String.fromCharCode(currentCharCode);
      let currentKey = currentLetter + 1;
      return currentKey;
    },

    onFileChanged() {
      // TODO: Check if file is comma seperated.
      // TODO: Check if here is an error to handle at (1).
      const file = this.$refs.projetMarcheFileUpload.files[0]; // TODO: (1)
      const reader = new FileReader();
      reader.onload = (e) => {
        this.invalidMsg = null;
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array', raw: true });
        let sheetName = workbook.SheetNames[SHEET_INDEX];

        let currentCharCode = 65;
        let currentLetter = String.fromCharCode(currentCharCode);
        let currentKey = currentLetter + 1;
        let currentColumn = workbook.Sheets[sheetName][currentKey];
        let j = 0;
        while (currentColumn) {
          const colElement = {
            label: currentColumn.v,
            index: j,
          };
          this.array.cols.push(colElement);
          this.array.previousCol.push({ ...colElement });
          currentKey = this.getNextCharacter(currentCharCode);
          currentColumn = workbook.Sheets[sheetName][currentKey];
          currentCharCode += 1;
          j++;
          if (j == 14) break;
        }

        let worksheet = workbook.Sheets[sheetName];
        let values = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
        this.array.rows = values.map((value) => Object.keys(value).map((k) => value[k]));
        this.currentStatus = 1;
      };
      reader.readAsArrayBuffer(file);
    },

    onColumnsSwapped(i, j) {
      const array = this.array;
      const tmp = array.cols[i];
      array.cols[i] = array.cols[j];
      array.cols[i].index = i;
      array.cols[j] = tmp;
      array.cols[j].index = j;

      const nameRef = this.mapping[i];
      this.mapping[i] = this.mapping[j];
      this.mapping[j] = nameRef;

      this.$forceUpdate();
    },

    restart() {
      this.array.previousCol = [];
      this.array.cols = [];
      this.array.rows = [];
      this.values = [];
      this.invalidMsg = null;

      this.$refs.projetMarcheFileUpload.value = null;
      this.currentStatus = 0;
    },

    isColumnInvalid(v, col) {
      return v[this.mapping[col.index]].length > 0;
    },

    isHeaderValid() {
      if (this.array.cols.length === 0) return false;

      const firstRow = this.array.cols;
      for (let i = 0; i < firstRow.length; i++) {
        const column = firstRow[i].label.toLowerCase();

        let everyValueMatched = false;
        console.log({ headerDefinition: this.headerDefinition });
        for (let j = 0; j < this.headerDefinition.length; j++) {
          const title = this.headerDefinition[j].toLowerCase();
          if (column === title) everyValueMatched = true;
        }
        if (!everyValueMatched) return false;
      }
      return true;
    },

    performRowsValidation() {
      this.values = this.array.rows.map((row) =>
        this.array.cols.reduce(
          (hash, col) => ({
            ...hash,
            [this.mapping[col.index]]: row[col.index],
          }),
          {}
        )
      );

      this.v$.$touch();

      const isSheetEmpty = this.array.rows.length === 0;
      if (isSheetEmpty) {
        this.invalidMsg = 'Aucune donnée n\'est chargée. Merci d\'importer un fichier "non vide".';
        return;
      }

      const isHeaderValid = this.isHeaderValid();
      if (!isHeaderValid) {
        const words = this.headerDefinition.join(', ');
        this.invalidMsg = `L'entête du fichier n'est pas valide, vérifiez bien que chacune des entêtes ci-après (${words}) sont présentes et bien conformes.`;
        return;
      }

      if (this.v$.$invalid) return;

      this.invalidMsg = null;
      this.values = this.values.map((value) => ({
        ...value,
        regimes: value.regimes.split(',').map((regime) => regime.toUpperCase().trim()),
        domaineMetiersCodes: value.domaineMetiersCodes.split(','),
        datePrevuLancement: moment(value.datePrevuLancement, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        datePrevuLAttribution: moment(value.datePrevuLAttribution, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }));

      this.$emit('uploaded', this.values);
      this.currentStatus = 3;
    },
  },
  watch: {
    reset: function (value) {
      if (value === true) {
        this.currentStatus = 0;
        this.restart();
        this.$emit('closed', true);
      }
    },
  },
};
</script>

<style>
.upload__table,
.upload__table th,
.upload__table td {
  border: 1px solid;
}

.upload__table th,
.upload__table td {
  width: 100px;
  overflow: hidden;
}

.upload__table th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.upload__table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
}

.upload__table {
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid;
  width: 100%;
}

.is-invalid {
  border: 2px solid red !important;
}
.is-invalid-text {
  font-weight: 400;
  color: red;
}

.upload__actions {
  margin: 10px 0;
  display: flex;
}

.upload__actions__elements {
  margin-left: auto;
}

.upload__actions__elements > input,
.upload__actions__elements > button {
  margin-left: 5px;
}

.upload__table {
}

.upload__table__th {
}
</style>

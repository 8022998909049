<template>
  <div class="layout-dashboard">
    <Toast position="top-right" />
    <div class="p-grid">
      <div v-if="isDonneurOrdre" class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-2">
          <span class="overview-title">Mes projets de marchés</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">Total</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">{{ stats?.countProjets || 0 }}</div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-1">
          <span class="overview-title">Mes appels d'offres</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">Total</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">
                {{ stats?.countAppelsOffres || 0 }}
              </div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-6 p-xl-4">
        <div class="card no-gutter widget-overview-box widget-overview-box-3">
          <span class="overview-title">Mes appels à manifestation d'intêrét</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number">Total</div>
              <div class="overview-subtext"></div>
            </div>
            <div class="p-col-6">
              <div class="overview-number">
                {{ stats?.countAppelsManifestations || 0 }}
              </div>
              <div class="overview-subtext"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-xl-12">
        <Message severity="error" v-if="currentPlan?.statut === 'NON_APPROUVE'">
          <b>Motif de correction : </b> {{ currentPlan.motifRenvoi }}
        </Message>
        <!--<Message severity="error" v-if='currentPlan?.statut === "REJETE"'>
              <b>Motif de rejet : </b> {{ currentPlan.motifRejet }}
            </Message>-->
        <div class="card no-gutter orders">
          <span>
            <Fieldset legend="Mes plans de passation de marché" style="background-color: #cce5ff; font-size: 1.3em">
              <p style="font-size: 0.7em; font-weight: bold; text-align: justify; width: 900px">
                Bonjour, <br />
                Votre plan de passation de marché pour l'année {{ selectedPpm?.annee || new Date().getFullYear() }} a
                été automatiquement créé. Vous pouvez dès à présent saisir vos projets de marché et les transmettre au
                secrétariat technique du CNSCL pour validation. Après validation de vos de projets de marché par le ST
                CNSCL, les appels d'offre et appels à manifestation d'intérêt sont créés depuis cet écran.
              </p>
            </Fieldset>
            <br />
          </span>
          <div class="p-d-flex p-jc-between">
            <div class="p-field">
              <label for="selectWipYear" class="p-mr-2">Sélectionner l'année de travail </label>
              <Dropdown
                @change="refreshStatsOnYear(selectedWipYear)"
                v-model="selectedWipYear"
                ref="selectWipYear"
                :options="plans"
                optionLabel="annee"
                placeholder="Année"
              />
            </div>

            <div>
              <Button
                @click="showUploadDialog"
                v-show="canEdit()"
                label="Importer des projets de marché"
                class="p-mr-2 p-mb-2 p-right"
              />
              <Button
                @click="showDialog"
                v-show="canEdit()"
                label="Ajouter un projet de marché"
                class="p-mr-2 p-mb-2 p-right"
              />
              <Button
                @click="enableOrDisableReview"
                v-show="canReview()"
                label="Réviser le plan de passation de marché"
                class="p-mr-2 p-mb-2"
              />
              <Button
                @click="enableOrDisableReview"
                v-show="isReviewing"
                label="Annuler la révision"
                class="p-mr-2 p-mb-2"
              />
            </div>
          </div>
          <div>
            <!-- {{ refeactoredProjetsMarchesList }} -->
            <DataTable
              :value="refeactoredPlans"
              v-model:expandedRows="refeactoredProjetsMarchesList"
              data-key="id"
              responsiveLayout="scroll"
            >
              <Column :expander="true" />

              <Column field="price" header="Année" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Année</span>
                  <b style="color: rgb(24, 14, 99)"> {{ slotProps.data.annee }} </b>
                </template>
              </Column>
              <Column field="price" header="Nombre de révision" :sortable="false" bodyStyle="text-indent:5em">
                <template #body="slotProps">
                  <span class="p-column-title"></span>
                  {{ slotProps.data.revision === -1 ? 0 : slotProps.data.revision }}
                </template>
              </Column>
              <Column field="category" header="Statut" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">STATUT</span>
                  <span v-if="slotProps.data.statut == 'VALIDE'" :class="'product-badge status-instock'">{{
                    enumMap[slotProps.data.statut]
                  }}</span>
                  <span v-if="slotProps.data.statut == 'TRANSMIS'" :class="'product-badge status-lowstock'">{{
                    enumMap[slotProps.data.statut]
                  }}</span>
                  <span v-if="slotProps.data.statut == 'NON_APPROUVE'" :class="'product-badge status-outofstock'">{{
                    enumMap[slotProps.data.statut]
                  }}</span>
                </template>
              </Column>
              <Column field="action" header="Actions" :sortable="false" bodyStyle="text-indent:1em">
                <template #body="slotProps">
                  <span class="p-column-title">Actions</span>

                  <Button
                    v-show="canSendPlan() && !isSuspendedMode()"
                    class="p-button-rounded p-button-warning"
                    label="Envoyer"
                    :style="{
                      background: '#00BFFF',
                      color: 'black',
                      borderColor: '#00BFFF',
                    }"
                    @click="onValidPlan(slotProps.data)"
                  />
                </template>
              </Column>

              <template #expansion="slotProps">
                <div class="orders-subtable">
                  <h5>Mes projets de marché</h5>
                  <DataTable
                    :value="slotProps.data.projetMarches"
                    responsiveLayout="scroll"
                    class="p-text-center"
                    :paginator="true"
                    :rows="10"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="{totalRecords} projets de marché"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  >
                    <Column
                      bodyStyle="text-indent:1em"
                      headerStyle="margin: 0 auto"
                      field="intitule"
                      header="Intitulé"
                      :sortable="true"
                      style="width: 10%"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title p-text-center">Intitulé</span>
                        <a
                          style="cursor: pointer; font-weight: bold"
                          @click="onOpenDetailsProjetMarche(slotProps.data.id)"
                          >{{ slotProps.data.intitule }}</a
                        >
                      </template>
                    </Column>
                    <Column
                      bodyStyle="text-indent:1em"
                      headerStyle="margin: 0 auto"
                      field="reference"
                      header="Référence"
                      :sortable="true"
                      style="width: 10%"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title p-text-center">Référence</span>
                        {{ slotProps.data.reference }}
                      </template>
                    </Column>
                    <Column
                      bodyStyle="text-indent:1em"
                      headerStyle="text-align: center"
                      field="naturePrestation.libelle"
                      header="Nature de prestation"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title"></span>
                        {{ slotProps.data.naturePrestation?.libelle }}
                      </template>
                    </Column>
                    <Column
                      bodyStyle="text-indent:1em"
                      headerStyle="text-align: center"
                      field="description"
                      header="Description"
                      :sortable="true"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title"></span>
                        {{ slotProps.data.description }}
                      </template>
                    </Column>
                    <Column
                      bodyStyle="text-indent:1em"
                      headerStyle="text-align: center"
                      field="regimes"
                      header="Régime"
                      :sortable="true"
                      style="width: 10%"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title"></span>
                        {{ slotProps.data.regimes.map((regime) => enumMap[regime]).join(', ') }}
                      </template>
                    </Column>
                    <Column
                      bodyStyle="text-indent:1em"
                      headerStyle="text-align: center"
                      field="datePrevuLancement"
                      header="Date prévue de lancement"
                      :sortable="true"
                      :style="{ width: '120px' }"
                    >
                      <template #body="slotProps">
                        <span class="p-column-title">Date de lancement</span>
                        {{ getFormattedDate(slotProps.data.datePrevuLancement) }}
                      </template>
                    </Column>
                    <Column
                      bodyStyle="text-indent:1em"
                      headerStyle="text-align: center"
                      field="datePrevuLAttribution"
                      header="Date prévue d'attribution"
                      :sortable="true"
                      :style="{ width: '120px' }"
                    >
                      <template #body="slotProps" :sortable="false">
                        <span class="p-column-title">Date attribution</span>
                        {{ getFormattedDate(slotProps.data.datePrevuLAttribution) }}
                      </template>
                    </Column>
                    <Column field="action" header="Actions">
                      <template #body="slotProps" bodyStyle="text-indent:1em">
                        <span class="p-column-title">Actions</span>
                        <ul style="list-style: none; padding: 0">
                          <li v-show="!isSuspendedMode() && (this.isReviewing || currentPlan?.statut === 'CREE')">
                            <a style="cursor: pointer" @click="onEditProjetMarche(slotProps.data)">
                              <i class="pi pi-pencil"></i> Modifier
                            </a>
                          </li>
                          <li v-show="!isSuspendedMode() && canDeleteProjetMarche(slotProps.data)">
                            <a style="cursor: pointer" @click="onRemoveProjetMarche(slotProps.data)">
                              <i class="pi pi-trash"></i> Supprimer
                            </a>
                          </li>
                          <li v-show="canAddAppelOffre(slotProps.data)">
                            <a
                              style="cursor: pointer"
                              @click="onAddAppelOffre(slotProps.data)"
                              v-tooltip.top="'Créer un appel d\'offre'"
                            >
                              <i class="pi pi-plus pointer"></i> Créer AO
                            </a>
                          </li>
                          <li v-show="canAddManifestationInteret(slotProps.data)">
                            <a
                              style="cursor: pointer"
                              @click="onAddManifestationInteret(slotProps.data)"
                              v-tooltip.top="'Créer un appel à manifestation d\'intérêt'"
                            >
                              <i class="pi pi-plus"></i> Créer AMI
                            </a>
                          </li>
                        </ul>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </template>
            </DataTable>
          </div>
        </div>
      </div>
    </div>

    <Sidebar
      v-model:visible="dialogs.detailProjetMarche.opened"
      position="right"
      class="p-sidebar-md"
      @close="onUnsetSelectedProjetMarcheId"
    >
      <h4>Détails du projet de marché</h4>
      <br />
      <ul style="list-style: none; padding: 0">
        <li class="detail-list-item">
          Intitulé :
          <b style="float: right">{{ selectedProjetMarche?.intitule }}</b>
        </li>
        <li class="detail-list-item">
          Type de régime :
          <b style="float: right">{{ selectedProjetMarche?.regimes.map((regime) => enumMap[regime]).join(', ') }}</b>
        </li>
        <li class="detail-list-item">
          Référence :
          <b style="float: right">{{ selectedProjetMarche?.reference }}</b>
        </li>
        <li class="detail-list-item">
          Nature de la prestation :
          <b style="float: right">{{ selectedProjetMarche?.naturePrestation?.libelle }}</b>
        </li>
        <li class="detail-list-item">
          Domaine(s) métier(s) :
          <b>
            <div v-for="d in selectedProjetMarche?.domaineMetiers" :key="d.id">- {{ d.libelle }}</div>
          </b>
        </li>
        <li class="detail-list-item">
          Description :
          <b style="float: right">{{ selectedProjetMarche?.description }}</b>
        </li>
        <li class="detail-list-item">
          Date prévue de lancement :
          <b style="float: right">{{ getFormattedDate(selectedProjetMarche?.datePrevuLancement) }}</b>
        </li>
        <li class="detail-list-item">
          Date prévue d'attribution :
          <b style="float: right">{{ getFormattedDate(selectedProjetMarche?.datePrevuLAttribution) }}</b>
        </li>
      </ul>
    </Sidebar>

    <Dialog
      header="Importer des projets de marché"
      v-model:visible="uploadDialog"
      @hide="onHideDialog"
      class="p-fluid"
      :modal="true"
      :style="{ width: '80vw' }"
    >
      <csv-uploader
        :reset="resetUploadDialog"
        :processState="processState"
        @uploaded="handleUpload"
        @closed="onHideDialog"
      />
    </Dialog>

    <Dialog
      :header="(this.projetMarche.id ? 'Modifier' : 'Ajouter') + ' un projet de marché'"
      v-model:visible="editDialog"
      @hide="onHideDialog"
      class="p-fluid"
      :modal="true"
      :style="{ width: '50vw' }"
    >
      <!-- <h1>{{ selectedPpm.id }}</h1> -->
      <p>
        <b
          ><u>NB:</u> Les champs dont le libellé se termine par <span class="p-text-bold text-red">*</span> sont
          obligatoires.</b
        >
      </p>
      <!-- <div class="p-field p-col-12">
        <label for="">Année de travail sélectionnée <span class="p-text-bold text-red">*</span></label>
        <Dropdown v-model="v$.projetMarche.ppmId.$model" :value="selectedPpm.id" :disabled="true" ref="selectWipYear" :options="plans" optionLabel="annee" placeholder="Année" />
      </div> -->
      <div class="p-field p-col-12">
        <label for="intitule-id">Intitulé <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.projetMarche.intitule.$model"
          id="intitule-id"
          type="text"
          :class="{
            'p-invalid': v$.projetMarche.intitule.$invalid && formsState.projetMarche.submitted,
          }"
        />
        <small v-if="v$.projetMarche.intitule.$invalid && formsState.projetMarche.submitted" class="p-error">
          L'intitulé du projet de marché est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label :for="'type-regime-id'">Type de régime <span class="p-text-bold text-red">*</span></label>
        <MultiSelect
          id="regimes-id"
          v-model="v$.projetMarche.regimes.$model"
          :class="{
            'p-invalid': v$.projetMarche.regimes.$invalid && formsState.projetMarche.submitted,
          }"
          :options="type_regimes"
          optionLabel="libelle"
          placeholder="Choisir"
          optionValue="id"
        />
        <small v-if="v$.projetMarche.regimes.$invalid && formsState.projetMarche.submitted" class="p-error">
          Le régime du projet de marché est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label :for="'reference-id'">Référence <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.projetMarche.reference.$model"
          id="reference-id"
          type="text"
          :class="{
            'p-invalid':
              (v$.projetMarche.reference.$invalid && formsState.projetMarche.submitted) ||
              isReferenceProjetMarcheExists,
          }"
        />
        <small v-if="v$.projetMarche.reference.$invalid && formsState.projetMarche.submitted" class="p-error">
          La référence du projet de marché est obligatoire.
        </small>
        <small v-if="isReferenceProjetMarcheExists" class="p-error">La référence saisie existe déjà.</small>
      </div>
      <div class="p-field p-col-12">
        <label :for="'nature-prestation-id'">Nature prestation <span class="p-text-bold text-red">*</span></label>
        <Dropdown
          id="nature-prestation-id"
          v-model="v$.projetMarche.naturePrestationId.$model"
          :class="{
            'p-invalid': v$.projetMarche.naturePrestationId.$invalid && formsState.projetMarche.submitted,
          }"
          :options="list_nature_prestation"
          optionLabel="libelle"
          optionValue="id"
          placeholder="Choisir"
        />
        <small v-if="v$.projetMarche.naturePrestationId.$invalid && formsState.projetMarche.submitted" class="p-error">
          La nature de la prestation du projet de marché est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="domaine-metier-id">Domaine(s) métier(s) <span class="p-text-bold text-red">*</span></label>
        <MultiSelect
          :class="{
            'p-invalid': v$.projetMarche.domaineMetiersIds.$invalid && formsState.projetMarche.submitted,
          }"
          v-model="v$.projetMarche.domaineMetiersIds.$model"
          :options="domainesMetiers.sort((a, b) => (a.libelle < b.libelle ? -1 : 1))"
          id="domaine-metier-ids"
          optionLabel="libelle"
          placeholder="Choisir"
          optionValue="id"
          :filter="true"
        >
          <template #value="slotProps">
            <div v-for="d of slotProps.value" :key="d">
              <!-- <div> {{ projetMarche.domaineMetiersIds.find(dm => dm.id === d).libelle }} </div> -->
              <div>{{ domainesMetiers.find((dm) => dm.id === d).libelle }}</div>
            </div>
          </template>
        </MultiSelect>
        <!-- <MultiSelect
          :class="{ 'p-invalid': v$.projetMarche.domaineMetiersIds.$invalid && formsState.projetMarche.submitted }"
          v-model="v$.projetMarche.domaineMetiersIds.$model"
          :options="domainesMetiers.sort((a, b) => (a.libelle < b.libelle ? -1 : 1))"
          id="domaine-metier-ids"
          optionLabel="libelle"
          placeholder="Choisir"
          optionValue="id"
          :filter="true"
        /> -->

        <small v-if="v$.projetMarche.domaineMetiersIds.$invalid && formsState.projetMarche.submitted" class="p-error">
          Le domaine métier de la prestation du projet de marché est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="description-id">Description du marché <span class="p-text-bold text-red">*</span></label>
        <Textarea
          id="description-id"
          v-model="v$.projetMarche.description.$model"
          :class="{
            'p-invalid': v$.projetMarche.description.$invalid && formsState.projetMarche.submitted,
          }"
          rows="5"
          cols="30"
        />
        <small v-if="v$.projetMarche.description.$invalid && formsState.projetMarche.submitted" class="p-error">
          La description de la prestation du projet de marché est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-lancement-id"
          >Date prévue de lancement <span class="p-text-bold text-red">*</span></label
        >
        <Calendar
          id="date-prevu-lancement-id"
          v-model="v$.projetMarche.datePrevuLancement.$model"
          :showIcon="true"
          dateFormat="dd/mm/yy"
          :class="{
            'p-invalid': v$.projetMarche.datePrevuLancement.$invalid && formsState.projetMarche.submitted,
          }"
        />
        <small v-if="!v$.projetMarche.datePrevuLancement.required && formsState.projetMarche.submitted" class="p-error">
          La date prévue de lancement du projet de marché est obligatoire.
        </small>
        <small v-if="v$.projetMarche.datePrevuLancement.$invalid && formsState.projetMarche.submitted" class="p-error">
          La date prévue de lancement du projet de marché doit être inférieur à la date d'attribution.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-attribution-id"
          >Date prévue d'attribution <span class="p-text-bold text-red">*</span></label
        >
        <Calendar
          id="date-prevu-attribution-id"
          v-model="projetMarche.datePrevuLAttribution"
          :showIcon="true"
          :class="{
            'p-invalid': v$.projetMarche.datePrevuLAttribution.$invalid && formsState.projetMarche.submitted,
          }"
          dateFormat="dd/mm/yy"
        />
        <small
          v-if="v$.projetMarche.datePrevuLAttribution.$invalid && formsState.projetMarche.submitted"
          class="p-error"
        >
          La date prévue d'attribution de la prestation du projet de marché est obligatoire.
        </small>
        <small
          v-if="v$.projetMarche.datePrevuLAttribution.$invalid && formsState.projetMarche.submitted"
          class="p-error"
        >
          La date prévue d'attribution du projet de marché doit être supérieur à la date prévue de lancement.
        </small>
      </div>

      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="onHideDialog" />
        <Button
          :label="this.projetMarche.id ? 'Enregistrer les modifications' : 'Ajouter le projet de marché'"
          class="p-button p-component p-button-outlined p-button-success"
          :loading="isSubmittingProjetMarche === 'SUBMIT_REQUESTED'"
          @click="handleCreateOrUpdateProjetMarche(v$.$invalid)"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="validDialog"
      :style="{ width: '600px' }"
      header="Voulez-vous soumettre le plan de passation de marché au CNSCL ?"
      :modal="true"
      @hide="onHideDialog"
      class="p-fluid"
    >
      <template #footer>
        <Button label="NON" class="p-button p-component p-button-outlined p-button-danger" @click="onHideDialog" />
        <Button
          label="OUI"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handlePlanTransmission"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="deleteDialog"
      @hide="onHideDialog"
      :style="{ width: '600px' }"
      header="Êtes-vous sûr de supprimer le projet de marché ?"
      :modal="false"
      class="p-fluid"
    >
      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="onHideDialog" />
        <Button
          label="Supprimer"
          icon="pi pi-delete"
          class="p-button p-component p-button-outlined p-button-success"
          @click="handleDeleteProjetMarche"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogs.appelOffre.opened"
      :modal="true"
      @hide="onHideDialog"
      header="Création d'un appel d'offre"
      class="p-fluid"
    >
      <p>
        <b
          ><u>NB:</u> Les champs dont le libellé se termine par <span class="p-text-bold text-red">*</span> sont
          obligatoires.</b
        >
      </p>
      <div class="p-field p-col-12">
        <label for="intitule-id">Intitulé <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.appelOffre.intitule.$model"
          id="intitule-id"
          type="text"
          :class="{
            'p-invalid': v$.appelOffre.intitule.$invalid && formsState.appelOffre.submitted,
          }"
        />
        <small v-if="v$.appelOffre.intitule.$invalid && formsState.appelOffre.submitted" class="p-error">
          L'intitulé de l'appel d'offre est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="reference-id">Référence <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.appelOffre.reference.$model"
          disabled="true"
          id="reference-id"
          type="text"
          :class="{
            'p-invalid': v$.appelOffre.reference.$invalid && formsState.appelOffre.submitted,
          }"
        />
        <small v-if="v$.appelOffre.reference.$invalid && formsState.appelOffre.submitted" class="p-error">
          La référence de l'appel d'offre est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="objet-id">Objet <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.appelOffre.objet.$model"
          id="objet-id"
          type="text"
          :class="{
            'p-invalid': v$.appelOffre.objet.$invalid && formsState.appelOffre.submitted,
          }"
        />
        <small v-if="v$.appelOffre.objet.$invalid && formsState.appelOffre.submitted" class="p-error">
          L'objet de l'appel d'offre est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="details-id">Détails de l'appel d'offre <span class="p-text-bold text-red">*</span></label>
        <Textarea
          id="price"
          v-model="v$.appelOffre.details.$model"
          :class="{
            'p-invalid': v$.appelOffre.details.$invalid && formsState.appelOffre.submitted,
          }"
          required="true"
          rows="10"
          cols="30"
        />
        <small v-if="v$.appelOffre.details.$invalid && formsState.appelOffre.submitted" class="p-error">
          Les détails de l'appel d'offre sont obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="instructions-id">Instructions de l'appel d'offre <span class="p-text-bold text-red">*</span></label>
        <Textarea
          id="instructions-id"
          v-model="v$.appelOffre.instructions.$model"
          :class="{
            'p-invalid': v$.appelOffre.instructions.$invalid && formsState.appelOffre.submitted,
          }"
          required="true"
          rows="10"
          cols="30"
        />
        <small v-if="v$.appelOffre.instructions.$invalid && formsState.appelOffre.submitted" class="p-error">
          Les instructions de l'appel d'offre est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-lancement-id">Date de publication <span class="p-text-bold text-red">*</span></label>
        <Calendar
          id="date-prevu-lancement"
          v-model="v$.appelOffre.datePublication.$model"
          :showIcon="true"
          dateFormat="dd/mm/yy"
          :class="{
            'p-invalid': v$.appelOffre.datePublication.$invalid && formsState.appelOffre.submitted,
          }"
        />
        <small v-if="v$.appelOffre.datePublication.$invalid && formsState.appelOffre.submitted" class="p-error">
          La date de publication de l'appel d'offre est obligatoire.
        </small>
        <small
          v-if="v$.appelOffre.datePublication.maxValue.$invalid && formsState.avisManifestation.submitted"
          class="p-error"
        >
          La date de publication de l'appel d'offre doit être inférieur à la date limite.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-attribution-id">Date limite <span class="p-text-bold text-red">*</span></label>
        <Calendar
          id="date-prevu-attribution"
          v-model="v$.appelOffre.dateLimite.$model"
          :showIcon="true"
          dateFormat="dd/mm/yy"
          :class="{
            'p-invalid': v$.appelOffre.dateLimite.$invalid && formsState.appelOffre.submitted,
          }"
        />
        <small v-if="v$.appelOffre.dateLimite.$invalid && formsState.appelOffre.submitted" class="p-error">
          La date limite de l'appel d'offre est obligatoire.
        </small>
        <small
          v-if="v$.appelOffre.dateLimite.minValue.$invalid && formsState.avisManifestation.submitted"
          class="p-error"
        >
          La date d'attribution de l'appel d'offre doit être supérieur à la date limite.
        </small>
      </div>
      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="onHideDialog" />
        <Button
          label="Créer un appel d'offre"
          class="p-button p-component p-button-outlined p-button-success"
          :loading="isSubmittingAppelOffre === 'SUBMIT_REQUESTED'"
          @click="handleCreateAppelOffre"
        />
      </template>
    </Dialog>

    <Dialog
      v-model:visible="dialogs.manifestationInteret.opened"
      header="Création un appel à manifestation d'intérêt"
      :modal="true"
      @hide="onHideDialog"
      class="p-fluid"
    >
      <p>
        <b
          ><u>NB:</u> Les champs dont le libellé se termine par <span class="p-text-bold text-red">*</span> sont
          obligatoires.</b
        >
      </p>
      <div class="p-field p-col-12">
        <label for="intitule-id">Intitulé <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.ami.intitule.$model"
          :class="{
            'p-invalid': v$.ami.intitule.$invalid && formsState.avisManifestation.submitted,
          }"
          id="intitule-id"
          type="text"
        />
        <small v-if="v$.ami.intitule.$invalid && formsState.avisManifestation.submitted" class="p-error">
          L'intitulé de l'appel à manifestation d'intérêt est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="objet">Objet <span class="p-text-bold text-red">*</span></label>
        <InputText
          v-model="v$.ami.objet.$model"
          :class="{
            'p-invalid': v$.ami.objet.$invalid && formsState.avisManifestation.submitted,
          }"
          id="objet"
          type="text"
        />
        <small v-if="v$.ami.objet.$invalid && formsState.avisManifestation.submitted" class="p-error">
          L'objet de l'appel à manifestation d'intérêt est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="reference-id">Référence <span class="p-text-bold text-red">*</span></label>
        <InputText
          id="reference-id"
          type="text"
          disabled="true"
          v-model="v$.ami.reference.$model"
          :class="{
            'p-invalid': v$.ami.reference.$invalid && formsState.avisManifestation.submitted,
          }"
        />
        <small v-if="v$.ami.reference.$invalid && formsState.avisManifestation.submitted" class="p-error">
          La référence de l'appel à manifestation d'intérêt est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="details">Détails de l'appel à manifestation d'intérêt</label>
        <Textarea
          id="details"
          v-model="v$.ami.details.$model"
          :class="{
            'p-invalid': v$.ami.details.$invalid && formsState.avisManifestation.submitted,
          }"
          required="true"
          rows="10"
          cols="30"
        />
        <small v-if="v$.ami.details.$invalid && formsState.avisManifestation.submitted" class="p-error">
          Les détails de l'appel à manifestation d'intérêt est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="instructions-id"
          >Instructions de l'appel à manifestation d'intérêt <span class="p-text-bold text-red">*</span></label
        >
        <Textarea
          id="instructions-id"
          v-model="v$.ami.instructions.$model"
          :class="{
            'p-invalid': v$.ami.instructions.$invalid && formsState.avisManifestation.submitted,
          }"
          required="true"
          rows="10"
          cols="30"
        />
        <small v-if="v$.ami.instructions.$invalid && formsState.avisManifestation.submitted" class="p-error">
          Les instructions de l'appel d'offre est obligatoire.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-lancement">Date de publication <span class="p-text-bold text-red">*</span></label>
        <Calendar
          id="date-prevu-lancement"
          :showIcon="true"
          dateFormat="dd/mm/yy"
          v-model="v$.ami.datePublication.$model"
          :class="{
            'p-invalid': v$.ami.datePublication.$invalid && formsState.avisManifestation.submitted,
          }"
        />
        <small
          v-if="v$.ami.datePublication.required.$invalid && formsState.avisManifestation.submitted"
          class="p-error"
        >
          La date de publication de l'appel à manifestation d'intérêt est obligatoire.
        </small>
        <small
          v-if="v$.ami.datePublication.maxValue.$invalid && formsState.avisManifestation.submitted"
          class="p-error"
        >
          La date de publication de l'appel à manifestation d'intérêt doit être inférieur à la date d'attribution.
        </small>
      </div>
      <div class="p-field p-col-12">
        <label for="date-prevu-attribution">Date limite <span class="p-text-bold text-red">*</span></label>
        <Calendar
          id="date-prevu-attribution"
          v-model="v$.ami.dateLimite.$model"
          :class="{
            'p-invalid': v$.ami.dateLimite.$invalid && formsState.avisManifestation.submitted,
          }"
          :showIcon="true"
          dateFormat="dd/mm/yy"
        />
        <small v-if="v$.ami.dateLimite.required.$invalid && formsState.avisManifestation.submitted" class="p-error">
          La date limite de l'appel à manifestation d'intérêt est obligatoire.
        </small>
        <small v-if="v$.ami.dateLimite.minValue.$invalid && formsState.avisManifestation.submitted" class="p-error">
          La date d'attribution de l'appel à manifestation d'intérêt doit être supérieur à la date d'attribution.
        </small>
      </div>
      <template #footer>
        <Button label="Annuler" class="p-button p-component p-button-outlined p-button-danger" @click="onHideDialog" />
        <Button
          label="Créer un appel à manifestation"
          class="p-button p-component p-button-outlined p-button-success"
          :loading="isSubmittingAvisManifestation === 'SUBMIT_REQUESTED'"
          @click="handleCreateManifestationInteret"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DateFormatMixin from '@/mixins/date-format';
import EnumFormatMixin from '@/mixins/enum-format';
import LoggedUserMixin from '@/mixins/logged-user';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { required } from '@vee-validate/rules';
import { useVuelidate } from '@vuelidate/core';
import { maxValue, minValue } from '@vuelidate/validators';
import { SUBMIT_FAILED, SUBMIT_REQUESTED, SUBMIT_SUCCEED } from '@/mixins/app-conts';
// import http from '../services/http.service';

import CsvUploader from '@/components/uploader/csv-uploader.vue';
import http from '../services/http.service';

export default {
  mixins: [DateFormatMixin, EnumFormatMixin, LoggedUserMixin],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    CsvUploader,
  },
  data() {
    return {
      refeactoredProjetsMarchesList: [],
      refeactoredPlans: [],
      selectedWipYear: null,
      today: new Date(),
      domaines: [],
      resetUploadDialog: false,
      processState: 'PENDING',
      domaine: null,
      isReviewing: false,
      isReferenceProjetMarcheExists: false,
      formsState: {
        projetMarche: {
          submitted: false,
        },
        appelOffre: {
          submitted: false,
        },
        avisManifestation: {
          submitted: false,
        },
      },
      projetMarche: {
        id: null,
        intitule: null,
        objet: null, // TODO: To add in backend.
        reference: null,
        description: null,
        datePrevuLancement: null,
        ppmId: null,
        datePrevuLAttribution: null,
        regimes: [],
        naturePrestationId: null,
      },
      appelOffre: {
        reference: null,
        intitule: null,
        objet_ao: null,
        details: null,
        instructions: null,
        motifRejet: null,
        dateLimite: null,
        datePublication: null,
        etat: 'CREE',
        projetMarcheId: null,
        domaineMetiersIds: [],
      },
      ami: {
        id: null,
        intitule: null,
        reference: null,
        objet: null,
        domaineMetiersIds: [],
        entreprisesChoisisIds: [],
        entreprisesAttribueesIds: [],
        projetMarcheId: null,
        details: null,
        instructions: null,
        datePublication: null,
        dateLimite: null,
        etat: 'CREE',
      },
      dialogs: {
        appelOffre: { opened: false },
        manifestationInteret: { opened: false },
        detailProjetMarche: { opened: false },
      },
      isEditing: false,
      type_marche: null,
      type_marches: ['EXCLUSIF', 'MIXTE', 'LIBRE'],
      mode_passation: null,
      mode_passations: ['DRP', 'AO', 'AMI'],
      type_regimes: [
        { id: 'EXCLUSIF', libelle: 'Exclusif' },
        { id: 'MIXTE', libelle: 'Mixte' },
        { id: 'NON_EXCLUSIF', libelle: 'Non exclusif' },
      ],
      projetsMarchesList: [], //
      uploadDialog: false,
      editDialog: false,
      validDialog: false,
      deleteDialog: false,
      revokeDialog: false,
      motif_rejet: '',
      role: null,
      id_ppm: null,
      nbr_revision: 0,
    };
  },
  validations() {
    return {
      // ppmId:{
      //    required:false,
      //  },
      projetMarche: {
        intitule: {
          required,
        },
        reference: {
          required,
        },
        description: {
          required,
        },
        datePrevuLancement: {
          required,
          maxValue: maxValue(this.projetMarche.datePrevuLAttribution),
        },
        datePrevuLAttribution: {
          required,
          minValue: minValue(this.projetMarche.datePrevuLancement),
        },
        regimes: {
          required,
        },
        naturePrestationId: {
          required,
        },
        domaineMetiersIds: {
          required,
        },
      },
      appelOffre: {
        reference: {
          required,
        },
        intitule: {
          required,
        },
        objet: {
          required,
        },
        details: {
          required,
        },
        instructions: {
          required,
        },
        dateLimite: {
          required,
          minValue: minValue(this.appelOffre.datePublication),
        },
        datePublication: {
          required,
          maxValue: maxValue(this.appelOffre.dateLimite),
        },
      },
      ami: {
        intitule: {
          required,
        },
        reference: {
          required,
        },
        objet: {
          required,
        },
        details: {
          required,
        },
        instructions: {
          required,
        },
        datePublication: {
          required,
          maxValue: maxValue(this.ami.dateLimite),
        },
        dateLimite: {
          required,
          minValue: minValue(this.ami.datePublication),
        },
      },
    };
  },
  mounted() {
    let year = new Date().getFullYear();
    this.fetchAllDomainesMetiers({
      queryParams: { limit: 100, sort: 'name,asc' },
    });
    this.fetchNaturesPrestations();
    this.fetchCurrentUserPlans();
    this.fetchStats({ queryParams: { annee: year } });
  },
  computed: {
    ...mapGetters({
      list_nature_prestation: 'naturePrestation/getAllEntities',
      plans: 'ppm/getAllEntities',
      selectedPpm: 'ppm/getEntity',
      stats: 'stats/getStats',
      currentPlan: 'ppm/getEntity',
      projetsMarches: 'projetsMarches/getPlansProjetsMarches',
      selectedProjetMarche: 'projetsMarches/getProjetMarcheFromPlan',
      isSubmittingProjetMarche: 'projetsMarches/getIsSubmitting',
      isSubmittingAppelOffre: 'appelsOffres/getIsSubmitting',
      isSubmittingAvisManifestation: 'ami/getIsSubmitting',
      isEditingProjetMarche: 'projetsMarches/getIsEditing',
      isLoadingProjetsMarches: 'projetsMarches/getIsLoading',
      domainesMetiers: 'domainesMetiers/getAllEntities',
    }),
  },

  methods: {
    ...mapActions({
      fetchStats: 'stats/fetchStats',
      fetchNaturesPrestations: 'naturePrestation/fetchAll',
      fetchCurrentUserPlans: 'ppm/fetchCurrentUserPlans',
      createOrUpdateProjetMarche: 'projetsMarches/createOrUpdate',
      updatePlan: 'ppm/update',
      deleteProjetMarche: 'projetsMarches/delete',
      createAppelOffre: 'appelsOffres/createOrUpdate',
      createManifestationInteret: 'ami/createOrUpdate',
      fetchAllDomainesMetiers: 'domainesMetiers/fetchAll',
    }),
    ...mapMutations({
      setSelectedPpmId: 'ppm/setSelectedEntityId',
    }),
    refreshStatsOnYear(e) {
      const target_copy = Object.assign({}, e);
      const year = target_copy.annee;
      const id = target_copy.id;
      this.setSelectedPpmId(year);

      this.projetMarche.ppmId = id;
      this.fetchStats({ queryParams: { annee: year } });

      this.refeactoredPlans = this.plans.filter((e) => {
        return e.annee == year;
      });
      this.refeactoredProjetsMarchesList = this.projetsMarchesList.filter((e) => {
        return e.planPassationMarcheAnnee == year;
      });
    },
    canReview() {
      if (!this.projetMarche.ppmId) return;
      const last_index = this.getLoggedUser().entreprise?.abonnements.length - 1;
      const lastSubscription = this.getLoggedUser().entreprise?.abonnements[last_index];

      const status = this.currentPlan && this.currentPlan.statut;

      return (
        !this.isSuscriptionSuspended(lastSubscription) &&
        !this.isReviewing &&
        (status === 'NON_APPROUVE' || status === 'VALIDE')
      );
    },

    canSendPlan() {
      const status = this.selectedPpm && this.selectedPpm.statut;
      // console.log(this.selectedPpm);
      return (
        (status === 'CREE' && this.selectedPpm && this.selectedPpm.projetMarches.length > 0) ||
        (this.isReviewing && (status === 'VALIDE' || status === 'NON_APPROUVE'))
      );
    },

    canEdit() {
      if (!this.projetMarche.ppmId) return;
      // console.log(this.getLoggedUser());
      const last_index = this.getLoggedUser().entreprise?.abonnements.length - 1;
      const lastSubscription = this.getLoggedUser().entreprise?.abonnements[last_index];

      const status = this.currentPlan && this.currentPlan.statut;
      return (
        !this.isSuscriptionSuspended(lastSubscription) &&
        (status === 'CREE' || (this.isReviewing && (status === 'VALIDE' || status === 'NON_APPROUVE')))
      );
    },

    isSuspendedMode() {
      const subscriptions = this.getLoggedUser().entreprise?.abonnements;
      return (
        !this.isInGracePeriod(subscriptions) && this.isSuscriptionSuspended(subscriptions[subscriptions.length - 1])
      );
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },

    canDeleteProjetMarche(projetMarche) {
      const status = this.currentPlan && this.currentPlan.statut;
      return (
        (this.isReviewing || status === 'CREE') &&
        projetMarche.countAppelsOffres === 0 &&
        projetMarche.countManifestationsInterets === 0
      );
    },

    canAddAppelOffre(projetMarche) {
      const status = this.currentPlan && this.currentPlan.statut;
      return this.isReviewing && status === 'VALIDE' && projetMarche.countAppelsOffres === 0;
    },

    canAddManifestationInteret(projetMarche) {
      const status = this.currentPlan && this.currentPlan.statut;
      return this.isReviewing && status === 'VALIDE' && projetMarche.countManifestationsInterets === 0;
    },

    enableOrDisableReview() {
      this.isReviewing = !this.isReviewing;
    },

    handleUpload(values) {
      http
        .post('/projets-marches/bulk/create', { values: values, ppmId: this.projetMarche.ppmId })
        .then(() => {
          this.fetchCurrentUserPlans();
          this.fetchStats({ queryParams: { annee: this.selectedPpm.annee } });

          this.$toast.add({
            severity: 'success',
            summary: 'Accomplie',
            detail: 'Les projets de marché ont bien été importés dans votre base.',
            life: 7000,
          });
          this.processState = 'PENDING';
          this.resetUploadDialog = true;
        })
        .catch(() => {
          this.processState = 'FAILED';
          this.$toast.add({
            severity: 'error',
            summary: 'Erreur',
            detail:
              "Une erreur est survenue, veuillez vérifier l'unicité des références et réessayer ou contactez votre administrateur.",
            life: 7000,
          });
        });
    },

    handleCreateOrUpdateProjetMarche() {
      this.formsState.projetMarche.submitted = true;
      const data = this.projetMarche;

      if (this.v$.projetMarche.$invalid) {
        return;
      }

      this.createOrUpdateProjetMarche({ data: { ...data, ppmId: this.selectedPpm.id } });
      this.formsState.projetMarche.submitted = false;
    },

    handleDeleteProjetMarche() {
      const id = this.selectedProjetMarche.id;
      this.deleteProjetMarche({ id });
      this.deleteDialog = false;
    },

    handlePlanTransmission() {
      //

      this.updatePlan({
        data: {
          entrepriseId: this.getLoggedUser().entreprise.id,
          id: this.selectedPpm.id,
          statut: 'TRANSMIS',
        },
        payload: {
          queryParams: { flag: 'SET_NEW_STATUS' },
        },
      });
      this.validDialog = false;
      this.isReviewing = false;
    },

    handleCreateAppelOffre() {
      this.formsState.appelOffre.submitted = true;
      if (this.v$.appelOffre.$invalid) {
        return;
      }
      this.createAppelOffre({
        data: {
          ...this.appelOffre,
          objet_ao: this.appelOffre.objet,
          domaineMetiersIds: this.selectedProjetMarche.domaineMetiers.map((o) => o.id),
        },
      });
    },

    handleCreateManifestationInteret() {
      this.formsState.avisManifestation.submitted = true;
      if (this.v$.ami.$invalid) {
        return;
      }
      this.createManifestationInteret({
        data: {
          ...this.ami,
          objet_ami: this.ami.objet,
          domaineMetiersIds: this.selectedProjetMarche.domaineMetiers.map((o) => o.id),
        },
      });
    },

    onAddAppelOffre(selectedProjetMarche) {
      this.$store.commit('projetsMarches/setSelectedEntityId', selectedProjetMarche.id);
      this.appelOffre.projetMarcheId = selectedProjetMarche.id;
      this.appelOffre.reference = selectedProjetMarche.reference;
      this.appelOffre.details = selectedProjetMarche.description;
      this.appelOffre.intitule = selectedProjetMarche.intitule;
      this.appelOffre.datePublication = new Date(selectedProjetMarche.datePrevuLancement);
      this.appelOffre.dateLimite = new Date(selectedProjetMarche.datePrevuLAttribution);
      this.dialogs.appelOffre.opened = true;
    },

    onAddManifestationInteret(selectedProjetMarche) {
      this.$store.commit('projetsMarches/setSelectedEntityId', selectedProjetMarche.id);
      this.ami.projetMarcheId = selectedProjetMarche.id;
      this.ami.reference = selectedProjetMarche.reference;
      this.ami.details = selectedProjetMarche.description;
      this.ami.intitule = selectedProjetMarche.intitule;
      this.ami.datePublication = new Date(selectedProjetMarche.datePrevuLancement);
      this.ami.dateLimite = new Date(selectedProjetMarche.datePrevuLAttribution);
      this.dialogs.manifestationInteret.opened = true;
    },

    onValidPlan(selectedProjetMarche) {
      this.$store.commit('projetsMarches/setSelectedEntityId', selectedProjetMarche.id);
      this.validDialog = true;
    },

    onRemoveProjetMarche(selectedProjetMarche) {
      this.$store.commit('projetsMarches/setSelectedEntityId', selectedProjetMarche.id);
      this.deleteDialog = true;
    },

    showUploadDialog() {
      this.uploadDialog = true;
      this.resetUploadDialog = false;
    },

    showDialog() {
      this.$store.commit('projetsMarches/setSelectedEntityId', null);
      // this.selectedProjetMarche.ppmId = this.selectedPpm.id;
      // console.log(this.selectedPpm);
      this.editDialog = true;
    },

    onEditProjetMarche(selectedProjetMarche) {
      this.$store.commit('projetsMarches/setSelectedEntityId', selectedProjetMarche.id);
      this.projetMarche = {
        intitule: selectedProjetMarche.intitule,
        regimes: selectedProjetMarche.regimes,
        reference: selectedProjetMarche.reference,
        description: selectedProjetMarche.description,
        id: selectedProjetMarche.id,
        ppmId: selectedProjetMarche.ppmId,
        naturePrestationId: selectedProjetMarche.naturePrestation.id,
        datePrevuLancement: new Date(selectedProjetMarche.datePrevuLancement),
        datePrevuLAttribution: new Date(selectedProjetMarche.datePrevuLAttribution),
        objet: selectedProjetMarche.objet,
        domaineMetiersIds: selectedProjetMarche.domaineMetiers.map((o) => o.id),
      };
      this.editDialog = true;
    },

    onOpenDetailsProjetMarche(projetMarcheId) {
      this.$store.commit('projetsMarches/setSelectedEntityId', projetMarcheId);
      this.dialogs.detailProjetMarche.opened = true;
    },

    onUnsetSelectedProjetMarcheId() {
      this.$store.commit('projetsMarches/setSelectedEntityId', null);
    },

    resetProjetMarcheForm() {
      this.projetMarche = {
        id: null,
        intitule: null,
        objet: null, // TODO: To add in backend.
        reference: null,
        description: null,
        ppmId: this.selectedPpm.id,
        datePrevuLancement: null,
        datePrevuLAttribution: null,
        regimes: [],
        naturePrestationId: null,
        domaineMetiersIds: [],
      };
      this.formsState.projetMarche.submitted = false;
    },

    resetAppelOffreForm() {
      this.appelOffre = {
        reference: null,
        intitule: null,
        objet_ao: null,
        details: null,
        motifRejet: null,
        dateLimite: null,
        datePublication: null,
        etat: 'CREE',
        projetMarcheId: null,
        domaineMetiersIds: [],
      };
      this.formsState.appelOffre.submitted = false;
    },

    resetAppelManifestationInteretForm() {
      this.ami = {
        id: null,
        intitule: null,
        reference: null,
        objet: null,
        domaineMetiersIds: [],
        entreprisesChoisisIds: [],
        entreprisesAttribueesIds: [],
        projetMarcheId: null,
        details: null,
        datePublication: null,
        dateLimite: null,
        etat: 'CREE',
      };
      this.formsState.avisManifestation.submitted = false;
    },

    resetForms() {
      this.resetProjetMarcheForm();
      this.resetAppelOffreForm();
      this.resetAppelManifestationInteretForm();
    },

    onHideDialog() {
      this.resetForms();

      this.editDialog = false;
      this.uploadDialog = false;
      this.validDialog = false;
      this.deleteDialog = false;
      this.revokeDialog = false;
      this.dialogs.manifestationInteret.opened = false;
      this.dialogs.detailProjetMarche.opened = false;
      this.dialogs.appelOffre.opened = false;
    },
  },

  watch: {
    projetsMarches: function (value) {
      this.projetsMarchesList = value;
    },

    isSubmittingProjetMarche: function (newValue, oldValue) {
      if (newValue === SUBMIT_SUCCEED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'success',
          summary: 'Accomplie',
          detail: 'Les modifications sur le projet de marché ont bien été effectuées.',
          life: 7000,
        });
        this.onHideDialog();
      }

      if (newValue === SUBMIT_FAILED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: 'Une erreur est survenue lors de la modification sur le projet de marché.',
          life: 7000,
        });
      }
    },

    isSubmittingAppelOffre: function (newValue, oldValue) {
      if (newValue === SUBMIT_SUCCEED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'success',
          summary: 'Accomplie',
          detail: "Les modifications sur l'appel d'offre ont bien été effectuées.",
          life: 7000,
        });
        this.onHideDialog();
      }

      if (newValue === SUBMIT_FAILED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: "Une erreur est survenue lors de la modification sur l'appel d'offre.",
          life: 7000,
        });
      }
    },

    isSubmittingAvisManifestation: function (newValue, oldValue) {
      if (newValue === SUBMIT_SUCCEED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'success',
          summary: 'Accomplie',
          detail: "Les modifications sur l'appel à manifestation d'intérêt ont bien été effectuées.",
          life: 7000,
        });
        this.onHideDialog();
      }

      if (newValue === SUBMIT_FAILED && oldValue === SUBMIT_REQUESTED) {
        this.$toast.add({
          severity: 'error',
          summary: 'Echec',
          detail: "Une erreur est survenue lors de la modification sur l'appel à manifestation d'intérêt.",
          life: 7000,
        });
      }
    },

    plans: function (plans) {
      const plan = plans[plans.length - 1];
      this.refreshStatsOnYear(plan);
      this.selectedWipYear = plan;
    },
  },
};
</script>

<style lang="scss">
.text-red {
  color: #db3c30;
}
.detail-list-item {
  padding: 10px 5px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 1rem;
  display: inline-block;
  width: 100%;
}
.detail-list-item:hover {
  background-color: #eeeeee;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-instock {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-outofstock {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-lowstock {
    background: #feedaf;
    color: #8a5340;
  }
}
</style>
